<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xs="12" lg="12">
        <b-card>
          <div>
          <h4 class="d-inline"><i class="fal fa-folder"></i> All Projects</h4>
          <router-link class="float-right btn btn-success" :to="{ name: 'Manager Add Project' }">Add Project <i class="fa fa-plus"></i></router-link></div>
          <hr class="clearfix my-4">
          <v-client-table :columns="columns" :data="projectData" :options="options" :theme="theme" id="dataTable">
            <div slot="status" slot-scope="props">
              <span v-if="props.row.status === 0">
                <button class="btn btn-sm btn-success w-100">Active</button>
              </span>
              <span v-if="props.row.status !== 0">
                <button class="btn btn-sm btn-danger w-100">Inactive</button>
              </span>
            </div>
            <span slot="name" slot-scope="props"><router-link :to="{ name: 'Company', params: { id: props.row.companyid } }">{{props.row.name}}</router-link></span>
            <span slot="p_start_date" slot-scope="props"></span>
            <span slot="p_start_date" slot-scope="props">{{ props.row.p_start_date | moment("MM/DD/YYYY") }}</span>
            <span slot="creation_date" slot-scope="props">{{ props.row.creation_date | moment("MM/DD/YYYY") }}</span>
            <div slot="actions" slot-scope="props">
              <b-btn size="sm" v-b-modal.editProject @click="sendInfo(props.row)" @ok="" variant="secondary btn">Edit <i class="fa fa-edit"></i></b-btn>
            </div>
          </v-client-table>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="editProject" ref="editModal" title="Edit Project" title-tag="h2" centered size="lg" hide-footer>
      <form @submit.prevent="validateBeforeSubmit" class="pb-2">
        <label>Project Name</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-validate="'required'" name="projectname" v-model="projectname" class="form-control" placeholder="Project Name" />
        </b-input-group>
        <i v-show="errors.has('projectname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('projectname')" class="help is-danger">The project name is required.</span>

        <label for="">Project Start Date</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-calendar"></i></b-input-group-text>
          </b-input-group-prepend>
          <datepicker :use-utc="false" :class="{'is-danger': errors.has('p_start_date')}" class="rt-date-picker w-50" name="p_start_date" placeholder="Dropdown" format="yyyy-MM-dd" v-validate="'required'" v-model="p_start_date"></datepicker>
        </b-input-group>
        <i v-show="errors.has('p_start_date')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('p_start_date')" class="help is-danger">The project start date is required.</span>

        <b-input-group class="mt-3 mb-2">
          <b-form-checkbox class="ml-2 mt-1" v-model="status" value="0" unchecked-value="1"> <p class="ml-3 mb-0">Project <span v-if="status == 0">Active</span><span v-if="status != 0"> Inactive</span></p></b-form-checkbox>
        </b-input-group>

        <i v-show="error" class="fa fa-exclamation-triangle mt-3"></i><span v-show="error" class="help is-danger">Error. Please contact admin. </span>
        <b-button variant="success" class="mt-4" type="submit" block>Save</b-button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import {ClientTable, Event} from 'vue-tables-2'
import Datepicker from 'vuejs-datepicker'

Vue.use(ClientTable)

export default {
  name: 'ManagerAllProjects',
  components: {
    ClientTable,
    Datepicker,
    Event,
  },
  data () {
    return {
      columns: ['status', 'projectname', 'p_start_date', 'ptn', 'creation_date', 'actions'],
      projectData: [],
      options: {
        headings: {
          projectname: 'Project Name',
          p_start_date: 'Start Date',
          ptn: 'Number',
        },
        sortable: ['status', 'projectname', 'ptn', 'creation_date',],
        filterable: ['status', 'projectname', 'ptn', 'creation_date',],
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        }
      },
      p_start_date: '',
      theme: 'bootstrap4',
      template: 'default',
      useVuex: false,
      status: "",
      projectname: "",
      p_start_date: "",
      id: "",
      error: false,
    }
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            id: this.id,
            status: this.status,
            projectname: this.projectname,
            p_start_date: this.$moment(this.p_start_date).format("YYYY-MM-DD"),
          })
          this.$http.post('/manager/edit/project', data)
          .then(response => {
            if (response.status === 200) {
              this.$toasted.show('Project Updated Successfully', {type: 'success', duration: '3000'})
              this.$refs.editModal.hide();
              this.loadData();
            }
          })
          .catch(error => {
            this.$toasted.show('Error', {type: 'error', duration: '3000'})
            this.error = true;
          })
          return;
        }
      });
    },
    sendInfo(props) {
      this.id = props.projectid;
      this.status = props.status;
      this.p_start_date = this.$moment(props.p_start_date).format();
      this.projectname = props.projectname;
    },
    loadData() {
      this.$http.get('/manager/get/projects')
      .then(response => {
        if (response.status === 200) {
          this.projectData = response.data.result;
        }
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
    }
  },
  mounted: function () {
    this.loadData();
  },
}
</script>
